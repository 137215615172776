var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ staticClass: "indigo ma-3", attrs: { text: "" } }, on),
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.mode === "new" ? "создание" : "правка") +
                      " "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(
              "юзер - " + _vm._s(_vm.mode === "new" ? "создание" : "правка")
            ),
          ]),
          _c(
            "div",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "username",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item2.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item2, "username", $$v)
                                  },
                                  expression: "item2.username",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "password",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item2.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item2, "password", $$v)
                                  },
                                  expression: "item2.password",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "terminal",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item2.terminal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item2, "terminal", $$v)
                                  },
                                  expression: "item2.terminal",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "role",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item2.role,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item2, "role", $$v)
                                  },
                                  expression: "item2.role",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "rateFee",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item2.rateFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item2, "rateFee", $$v)
                                  },
                                  expression: "item2.rateFee",
                                },
                              }),
                              _vm.mode === "edit"
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: "token",
                                      outlined: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.item2.token,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item2, "token", $$v)
                                      },
                                      expression: "item2.token",
                                    },
                                  })
                                : _vm._e(),
                              _c("v-text-field", {
                                attrs: {
                                  label: "comment",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item2.comment,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item2, "comment", $$v)
                                  },
                                  expression: "item2.comment",
                                },
                              }),
                              _vm.mode === "edit"
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: "status",
                                      outlined: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.item2.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item2, "status", $$v)
                                      },
                                      expression: "item2.status",
                                    },
                                  })
                                : _vm._e(),
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  label: "fee",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item2.fee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item2, "fee", $$v)
                                  },
                                  expression: "item2.fee",
                                },
                              }),
                              _vm.mode === "edit"
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: "balance",
                                      outlined: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.item2.balance,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item2, "balance", $$v)
                                      },
                                      expression: "item2.balance",
                                    },
                                  })
                                : _vm._e(),
                              _c("v-text-field", {
                                attrs: {
                                  label: "country",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item2.country,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item2, "country", $$v)
                                  },
                                  expression: "item2.country",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Исключить чаты №,№,№",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item2.excludedChats,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item2, "excludedChats", $$v)
                                  },
                                  expression: "item2.excludedChats",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Отмена ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.ok },
                },
                [_vm._v(" Ок ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }